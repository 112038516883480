import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import guest from '@/router/middleware/guest'
import { AuthRoutes } from '@/router/routes/auth'
import general from '@/router/routes/general'
import LoginUser from '@/views/auth/LoginUser.vue'
import Forgot from '@/views/auth/Forgot.vue'
import Register from '@/views/auth/SetPasswordForNewUser.vue'
import PasswordChanged from '@/views/auth/PasswordChanged.vue'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: AuthRoutes.login,
    components: { auth: LoginUser },
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/forgot',
    name: AuthRoutes.forgot,
    components: { auth: Forgot },
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/user-password-set',
    name: AuthRoutes.register,
    components: { auth: Register },
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/password-changed',
    name: AuthRoutes.passwordChanged,
    components: { auth: PasswordChanged },
    meta: {
      middleware: [guest]
    }
  },
  ...general
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// Middlewares
function nextFactory(context: any, middleware: any, index: number) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware) {
    return context.next
  }

  return () => {
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }

    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
