
























import Vue from 'vue'
import Component from 'vue-class-component'
import tokenHelper from './helpers/token'
import { Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import http from './shared/helpers/http'
import IResponse from './shared/interfaces/response.interface'
import SettingResponse from '@/modules/settings/interfaces/setting-response.interface'
import { UserActions } from './store/modules/user/user.actions'
import { UserGetters } from './store/modules/user/user.getters'
import { LogoGetters } from './store/modules/logo/logo.getters'
import { UserMutations } from './store/modules/user/user.mutations'
import ModalDialog from '@/components/layout/ModalDialog.vue';
import Topnav from '@/components/layout/Topnav.vue';

@Component({
  components: {
    Topnav,
    ModalDialog
  },
  computed: {
    ...mapGetters({
      token: UserGetters.getToken,
      isUserAuth: UserGetters.getUser,
      refreshing: UserGetters.getRefreshing,
      logo: LogoGetters.getLogo
    })
  }
})
export default class App extends Vue {
  token!: string
  refreshing!: boolean
  isUserAuth!: any
  details: any = null
  sidebarStatus: null | boolean = null

  @Watch('token') private onTokenChange(): void {
    if (!this.refreshing && this.token) this.$store.dispatch(UserActions.refreshUser)
  }

  async created(): Promise<void> {
    this.$root.$on('sidebarStatus', (status: boolean) => {
      localStorage.setItem('sidebarStatus', status ? 'collapsed' : 'open')
      this.sidebarStatus = status
    })
    this.setInitialSidebarStatus()
    if (!this.isUserAuth && tokenHelper.getToken() && !this.refreshing) {
      await this.$store.dispatch(UserActions.refreshUser)
    }

    if (tokenHelper.isTokenSet()) {
      this.$store.commit(
        UserMutations.setRefresh,
        setInterval(() => {
          this.$store.dispatch(UserActions.refreshToken)
        }, 600000)
      )
    }

    if (tokenHelper.isTokenSet()) {
      http.get('settings/general').then((response: IResponse<SettingResponse[]>) => {
        document.title = response?.data?.data[0]?.value
          ? `${process.env.VUE_APP_TITLE} | ${response.data.data[0].value}`
          : process.env.VUE_APP_TITLE
        this.details = response.data.data[0].value
      })
    }
  }

  private setInitialSidebarStatus() {
    const presetStatus = localStorage.getItem('sidebarStatus')
    if (!presetStatus) {
      const initialStatus = window.innerWidth < 1240
      this.sidebarStatus = initialStatus
      localStorage.setItem('sidebarStatus', initialStatus ? 'collapsed' : 'open')
      return
    }
    this.sidebarStatus = presetStatus === 'collapsed'
  }
}
